<template>
  <el-dialog
    :visible.sync="show"
    width="560px"
    top="14%"
    :close-on-click-modal="false"
    class="access-add-dialog dialogform"
    :title="accessobj.accessId ? $t('dc:编辑权限') : $t('dc:新增权限')"
  >
    <el-form
      style="width: 524px"
      label-position="right"
      label-width="120px"
      :inline="false"
      ref="formData"
      :model="formData"
      :rules="rules"
    >
      <el-form-item :label="$t('dc:产品名称')" prop="projectId" class="">
        <el-select
          v-model="formData.projectId"
          :placeholder="$t('dc:请选择')"
          style="width: 100%"
          filterable
          @change="changeProduct"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in productList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('dc:权限名称')" prop="accessName" class="">
        <el-input
          :class="{ redFont: !showAccessName }"
          v-model="formData.accessName"
          :placeholder="$t('dc:请输入')"
          maxlength="100"
          show-word-limit
          class="show-word-limit55"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('dc:模块')" prop="moduleId" class="">
        <el-select
          v-model="formData.moduleId"
          :placeholder="$t('dc:请选择')"
          filterable
          style="width: 100%"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in moduleList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('dc:编码')" prop="accessCode" class="">
        <el-input
          :class="{ redFont: !showAccessCode }"
          v-model="formData.accessCode"
          :placeholder="$t('dc:请输入')"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('dc:描述')" prop="description" class="">
        <el-input
          type="textarea"
          :class="{ redFont: !showAccessCode }"
          :placeholder="$t('dc:请输入')"
          v-model="formData.description"
          maxlength="200"
          :autosize="{ minRows: 4, maxRows: 4 }"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button class="" @click="show = false">{{ $t('dc:取消') }}</el-button>
      <el-button class="btn-black" type="info" @click="submitForm('formData')">{{
        $t('dc:确认')
      }}</el-button>
    </span>
    <el-dialog
      width="30%"
      :visible.sync="innerVisible"
      append-to-body
      class="acctount-detail-dialog"
      :close-on-click-modal="false"
      :show-close="false"
      :title="$t('dc:确认')"
    >
      <div class="fontW3">{{ content }}</div>
      <div slot="footer">
        <el-button class="" @click="innerVisible = false">{{ $t('dc:否') }}</el-button>
        <el-button class="btn-black" type="info" @click="add4Edit">{{ $t('dc:是') }}</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
export default {
  name: 'accessDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: String,
    typeOptions: {
      type: Array,
      default: () => [],
    },
    submitData: {
      type: Array,
      default: () => [],
    },

    accessobj: {},
    max: Number,
  },
  data() {
    var accessNameRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showAccessName = false
      } else {
        this.showAccessName = true
        callback()
      }
    }
    var accessCodeRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showAccessCode = false
      } else {
        this.showAccessCode = true
        callback()
      }
    }
    var descriptionRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
        this.showAccessCode = false
      } else {
        this.showAccessCode = true
        callback()
      }
    }
    return {
      loading: false,
      innerVisible: false,
      show: this.visible,
      accessid: this.accessobj.accessId,
      showAccessName: true,
      showModule: true,
      showAccessCode: true,
      currentTypeConfig: {},
      content: this.$t('dc:您确认添加此访问权限吗?'),
      formData: {
        projectId: '',
        accessName: '',
        moduleId: '',
        accessCode: '',
        description: '',
      },
      rules: {
        projectId: [
          { required: true, message: this.$t('dc:请选择'), type: 'number', trigger: 'change' },
        ],
        accessName: [{ required: true, validator: accessNameRoute, trigger: ['blur', 'change'] }],
        moduleId: [{ required: true, message: this.$t('dc:请选择'), trigger: ['change'] }],
        accessCode: [{ required: true, validator: accessCodeRoute, trigger: ['blur', 'change'] }],
        description: [{ required: true, message: this.$t('dc:请按规则输入') }],
      },
      productList: [],
      moduleList: [],
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  created() {
    this.initEvent()
  },
  mounted() {
    if (this.accessobj.accessId && this.accessobj.projectId) {
      this.content = this.$t('dc:您确认编辑此访问权限吗?')
      this.getAccessInfo()
      this.getModuleSelectList(this.accessobj.projectId)
    }
  },
  methods: {
    async initEvent() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.productList = data
      }
    },
    /**
     * 获取module的下拉选项
     */
    async getModuleSelectList(id) {
      let { status, data, msg } = await $Api.access.getModuleList(id)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.moduleList = data
      }
    },
    /**
     * 获取权限详情
     */
    async getAccessInfo() {
      let { status, data } = await $Api.access.accessInfo(this.accessid)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.formData = data
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.innerVisible = true
        } else {
          return false
        }
      })
    },
    async add4Edit() {
      if (this.accessobj.accessId && this.accessobj.projectId) {
        const { status, msg } = await $Api.access.updataAccess(this.formData)
        if (status === this.$RESPONSE_STATUS.SUCCESS) {
          this.$message({
            showClose: true,
            message: msg || this.$t('dc:成功'),
            type: 'success',
          })
          this.$emit('queryApi')
          this.show = false
          this.innerVisible = false
        } else {
          this.innerVisible = false
        }
      } else {
        const { status, msg } = await $Api.access.addAccess(this.formData)
        if (status === this.$RESPONSE_STATUS.SUCCESS) {
          this.$message({
            showClose: true,
            message: msg || this.$t('dc:成功'),
            type: 'success',
          })
          this.$emit('queryApi')
          this.show = false
          this.innerVisible = false
        } else {
          this.innerVisible = false
        }
      }
    },
    changeProduct() {
      this.formData.moduleId = ''
      this.getModuleSelectList(this.formData.projectId)
    },
  },
}
</script>
<style lang="scss">
.access-add-dialog {
  .el-form-item__label {
    font-size: 14px;
  }
  .redFont .el-input__inner,
  .redFont .el-input__count-inner {
    color: red;
  }
}
.el-dialog__footer .el-button {
  min-width: 100px;
}
.fontW3 {
  color: #000;
  font-weight: 600;
}
</style>
